.users {
  /* margin: 2rem auto; */
  background-color: rgb(0, 0, 0);
  padding: 1rem;
  padding-top: 0%;
  /* border-radius: 12px; */
  /* max-width: 40rem; */
  text-align: center;
}

.head_number {
  font-family: proxima-nova, sans-serif;
  font-size: 5rem;
  color: rgb(111, 230, 160);
}

.head_text {
  font-family: 'Arvo', serif;
  color: rgb(21, 244, 238);
  font-size: 3.4rem;
  font-weight: bold;
  text-transform: uppercase;
}

.intro{
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 3.4rem;
  width: 40vw;
  margin: 0 auto;
}

a{
  color: white;
}

@media screen and (max-width: 1280px) {
  .intro{
    width: 80vw;
  }
}

@media screen and (max-width: 576px) {
  .head_text {
    font-size: 2rem;
  }

  .intro{
    color: white;
    font-family: proxima-nova, sans-serif;
    font-size: 21px;
    font-weight: 400;
    width: 75vw;
  }
}

/* .users ul {
  list-style: none;
  margin: 0;
  padding: 0;
} */