@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: proxima-nova, sans-serif;
}

body {
  margin: 0;
  background-color: #000000;
  /* text-transform: uppercase; */
}

/* button {
  font: inherit;
  cursor: pointer;
  background-color: #800040;
  color: white;
  border: 1px solid #800040;
  border-radius: 8px;
  padding: 0.75rem 2rem;
}

button:hover,
button:active {
  background-color: #630032;
  border-color: #630032;
} */
.User_user__AwEVI {
  /* margin: 1rem 0; */
  font-family: proxima-nova, sans-serif;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  /* padding-left: 25%; */
  /* padding-right: 10%; */
  font-weight: bold;
  /* border-bottom: 1px solid black; */
  border-top: 1px solid rgb(255, 255, 255);
  /* text-transform: uppercase; */
}


.User_user__AwEVI:nth-last-of-type(1) {
  border-bottom: 1px solid rgb(255, 255, 255);
}

/* .user:hover {
  color: rgb(111, 230, 160);
} */

.User_content__1s7vJ {
  display: flex;
  align-items: center;
  width: 40vw;
  margin: 0 auto;
}

.User_number__wAZvG {
  text-align: center;
  flex-direction: row;
  position: absolute;
  right: 71vw;
  /* flex-basis: 5%; */
  font-size: 18px;
  /* padding-left: 20rem; */
  color: rgb(21, 244, 238);
}

/* .sign_time {
  
  text-align:right;
  flex-direction: row;
  flex-basis: 50%;
  font-size: 12px;
  padding-right: 2rem;
} */

.User_sign_name__3trtT {
  text-align: left;
  flex-direction: row;
  /* position: absolute;
  left: 25%; */
  width: 100%;
  /* flex-basis: 95%; */
  font-size: 18px;
  font-weight: 400;
  padding-left: 0rem;
  margin: 0 auto;
}

@media screen and (max-width: 1280px) {
  .User_content__1s7vJ{
    width: 80vw;
  }

  .User_number__wAZvG{
    right: 92vw;
  }
  
}

@media screen and (max-width: 576px) {
  .User_content__1s7vJ{
    width: 75vw;
  }

  .User_number__wAZvG{
    right: 89.5vw;
  }
  
}

/* @media screen and (max-width: 576px) {
  .user {
    padding: 1rem 1.5%;
  }

  .content {
    width: 90vw;
  }

  .number {
    position: unset;
    right: unset;
    flex-basis: 10%;
    padding-right: 5px;
    font-size: 21px;
  }

  .sign_name {
    flex-basis: 90%;
    width: unset;
    font-size: 21px;
  }
} */
.Users_users__23CR4 {
  /* margin: 2rem auto; */
  background-color: rgb(0, 0, 0);
  padding: 1rem;
  padding-top: 0%;
  /* border-radius: 12px; */
  /* max-width: 40rem; */
  text-align: center;
}

.Users_head_number__E6-_7 {
  font-family: proxima-nova, sans-serif;
  font-size: 5rem;
  color: rgb(111, 230, 160);
}

.Users_head_text__lJfz3 {
  font-family: 'Arvo', serif;
  color: rgb(21, 244, 238);
  font-size: 3.4rem;
  font-weight: bold;
  text-transform: uppercase;
}

.Users_intro__b2x68{
  color: white;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 3.4rem;
  width: 40vw;
  margin: 0 auto;
}

a{
  color: white;
}

@media screen and (max-width: 1280px) {
  .Users_intro__b2x68{
    width: 80vw;
  }
}

@media screen and (max-width: 576px) {
  .Users_head_text__lJfz3 {
    font-size: 2rem;
  }

  .Users_intro__b2x68{
    color: white;
    font-family: proxima-nova, sans-serif;
    font-size: 21px;
    font-weight: 400;
    width: 75vw;
  }
}

/* .users ul {
  list-style: none;
  margin: 0;
  padding: 0;
} */
