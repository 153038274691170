.user {
  /* margin: 1rem 0; */
  font-family: proxima-nova, sans-serif;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  /* padding-left: 25%; */
  /* padding-right: 10%; */
  font-weight: bold;
  /* border-bottom: 1px solid black; */
  border-top: 1px solid rgb(255, 255, 255);
  /* text-transform: uppercase; */
}


.user:nth-last-of-type(1) {
  border-bottom: 1px solid rgb(255, 255, 255);
}

/* .user:hover {
  color: rgb(111, 230, 160);
} */

.content {
  display: flex;
  align-items: center;
  width: 40vw;
  margin: 0 auto;
}

.number {
  text-align: center;
  flex-direction: row;
  position: absolute;
  right: 71vw;
  /* flex-basis: 5%; */
  font-size: 18px;
  /* padding-left: 20rem; */
  color: rgb(21, 244, 238);
}

/* .sign_time {
  
  text-align:right;
  flex-direction: row;
  flex-basis: 50%;
  font-size: 12px;
  padding-right: 2rem;
} */

.sign_name {
  text-align: left;
  flex-direction: row;
  /* position: absolute;
  left: 25%; */
  width: 100%;
  /* flex-basis: 95%; */
  font-size: 18px;
  font-weight: 400;
  padding-left: 0rem;
  margin: 0 auto;
}

@media screen and (max-width: 1280px) {
  .content{
    width: 80vw;
  }

  .number{
    right: 92vw;
  }
  
}

@media screen and (max-width: 576px) {
  .content{
    width: 75vw;
  }

  .number{
    right: 89.5vw;
  }
  
}

/* @media screen and (max-width: 576px) {
  .user {
    padding: 1rem 1.5%;
  }

  .content {
    width: 90vw;
  }

  .number {
    position: unset;
    right: unset;
    flex-basis: 10%;
    padding-right: 5px;
    font-size: 21px;
  }

  .sign_name {
    flex-basis: 90%;
    width: unset;
    font-size: 21px;
  }
} */