@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: proxima-nova, sans-serif;
}

body {
  margin: 0;
  background-color: #000000;
  /* text-transform: uppercase; */
}

/* button {
  font: inherit;
  cursor: pointer;
  background-color: #800040;
  color: white;
  border: 1px solid #800040;
  border-radius: 8px;
  padding: 0.75rem 2rem;
}

button:hover,
button:active {
  background-color: #630032;
  border-color: #630032;
} */